import React, { Component } from "react"
import IndexPage from "./home" // ./coming-soon"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { deadline: "January, 10, 2030" }
  }
  render() {
    return <></>
  }
}
export default IndexPage
